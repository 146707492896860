// extracted by mini-css-extract-plugin
export var containerHotShot = "GamePageDescription-module--container-hot-shot--24J3B";
export var container = "GamePageDescription-module--container--h9e04";
export var topSeperator = "GamePageDescription-module--top-seperator--36758";
export var bottomSeperator = "GamePageDescription-module--bottom-seperator--1w9Az";
export var leftImage = "GamePageDescription-module--left-image--681-X";
export var rightImage = "GamePageDescription-module--right-image--tELhM";
export var logo = "GamePageDescription-module--logo--3cbcZ";
export var sectionContent = "GamePageDescription-module--section-content--1KxkC";
export var heading = "GamePageDescription-module--heading--3Nn6u";
export var headingSpacer = "GamePageDescription-module--heading-spacer--2fWsy";
export var content = "GamePageDescription-module--content--ACuiq";
export var imageContainer = "GamePageDescription-module--image-container--1N8zB";
export var rightImageHotShot = "GamePageDescription-module--right-image-hot-shot--3Mabi";