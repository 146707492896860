import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import Head from "../components/Head/Head"
import GamePageDescription from "../components/GamePageDescription/GamePageDescription"
import ImageCarousel from "../components/ImageCarousel/ImageCarousel"
import VideoPlayer from "../components/VideoPlayer/VideoPlayer"

export const query = graphql`
  query($slug: String!) {
    contentfulGamePage(slug: { eq: $slug }) {
      title
      backgroundColor
      fontColor
      logo {
        gatsbyImageData(placeholder: BLURRED, width: 250)
      }
      backgroundImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      description {
        raw
        __typename
        references {
          __typename
          contentful_id
          image {
            gatsbyImageData(placeholder: BLURRED)
            description
            contentful_id
          }
          url
          altText
        }
      }
      contentful_id
      videoUrl
      slug
      leftImage {
        gatsbyImageData(placeholder: BLURRED)
        description
        title
      }
      rightImage {
        gatsbyImageData(placeholder: BLURRED, width: 600)
        description
        title
      }
      promotionals {
        description
        title
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`

const GamePage = ({ data }) => {
  const info = data.contentfulGamePage
  const { backgroundColor } = info
  const sectionData = {
    title: info.title,
    backgroundColor: info.backgroundColor,
    textColor: info.fontColor,
    content: info.description,
    bgImage: info.backgroundImage && info.backgroundImage.gatsbyImageData,
    leftImage: info.leftImage,
    rightImage: info.rightImage,
    logo: info.logo,
  }

  const Carousel = (
    <ImageCarousel
      images={info.promotionals}
      baseColor={`rgba(${backgroundColor},1)`}
    />
  )

  const Player = <VideoPlayer url={info.videoUrl} />

  return (
    <Layout backgroundColor={`rgba(${backgroundColor},1)`}>
      <Head title={sectionData.title}></Head>
      <GamePageDescription {...sectionData} />
      {Carousel}
      {Player}
    </Layout>
  )
}

export default GamePage
